import logotype from '@assets/logo-dobro.svg';
import logotypeCoupon from '@assets/logo-coupon.png';
import { preloadImages } from './shared/utils/helpers/preload';

export const TOKENT_KEY_NAME = 'dobrobet-token';
export const DEFAULT_CURRENCY = 'UAH';
export const DEFAULT_TIMEZONE = 3;
export const DEFAULT_LANG = 'en';
export const AVAILABLE_LANGS = ['ru', 'uk', 'en', 'kk', 'az', 'uz', 'da'];
export const ONLY_CURRENCY: string | null = null;
export const EXCLUDED_CURRENCY: string[] | null = null;
export const AVAILABLE_THEMES = ['dark-blue'];
export const DEFAULT_THEME = 'dark-blue';
export const COMPANY_NAME = 'Dobro.bet';
export const SUPPORT_LINK = 'https://t.me/BetDobro_Bot?start=bk';
export const SOCIAL_LINK_TG = 'https://t.me/dobro_bk';
export const SOCIAL_LINK_INST = '';
export const SOCIAL_LINK_VK = '';
export const LOGO_BOOMAKER_RATINGS = 'https://bookmaker-ratings.ru/review/dobrobet/';
export const ANDROID_APP = 'https://drive.google.com/file/d/1qANZbv0c91PYk8FO6CNqel8o555-WPvL/view';
export const EXCLUDE_CASINO_CATEGORY = ['gameslobby', 'roshambo', 'arcade', 'moon', 'jetx'];
export const DEFAULT_LOGOTYPE = logotype;
export const MainLogotype = {
  DARK_PURPLE: logotype,
  BLUE: logotype,
  DARK_BLUE: logotype,
  LIGHT_BLUE: logotype,
};
export const CouponLogotype = {
  DARK_PURPLE: logotypeCoupon,
  BLUE: logotypeCoupon,
  DARK_BLUE: logotypeCoupon,
  LIGHT_BLUE: logotypeCoupon,
};

export const AppFeature = {
  DIGITAIN_GAMES: false,
  BONUS_AND_PROMO: false,
  REGISTRATION_TELEGRAM: false,
  REGISTRATION_PHONE: false,
  KYC_VERIFICATION: true,
  ADAPTIVE_BANNERS: false,
};

preloadImages([logotype, logotypeCoupon]);
